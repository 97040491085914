const menuBar = document.getElementById('menu-bar');
const dropdownButton = document.getElementById('dropdown-button');
const dropdownMenu = document.getElementById('dropdown-menu');

menuBar.addEventListener('click', () => {
    dropdownButton.checked = !dropdownButton.checked;
    if (dropdownButton.checked) {
        dropdownMenu.style.transform = 'scaleY(1)';
        const rect = menuBar.getBoundingClientRect();
        dropdownMenu.style.top = rect.y + rect.height + 'px';
    } else {
        dropdownMenu.style.transform = 'scaleY(0)';
    }
    
})

function closeMenu() {
    dropdownButton.checked = false;
    dropdownMenu.style.transform = 'scaleY(0)';
}